import axios from "axios";
import store from "@/store/index";
import { Notification, MessageBox } from "element-ui";
import { getToken } from "@/utils/storage";
import { message } from "@/utils/resetMessage";
import { setToken, removeToken } from "./storage";

axios.defaults.baseURL = window.baseUrl;
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;
axios.defaults.retry = 3; //重试次数
axios.defaults.retryDelay = 1000; //重试间隔

//对外接口
export function request({ url, params, method, timeout }) {
  if (!method) {
    method = "post";
  }
  if (!timeout) {
    timeout = 10000;
  }
  if (method === "get") {
    return get(url, params);
  } else if (method === "post") {
    return post(url, params, timeout);
  }
}

//封装get方法
function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then((res) => {
        if (res.code == constants.interfaceResult.success) {
          resolve(res.data);
        } else {
          if (getToken() && res.msg !== "") {
            message.error({
              message: res.msg,
              showClose: true,
              duration: 4000,
              offset: 100,
            });
          }
          reject(res.msg);
        }
      })
      .catch((err) => {
        reject(err.msg);
      });
  });
}

//封装post方法
function post(url, params, timeout) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { timeout: timeout })
      .then((res) => {
        console.log("lllllllllllllllll:" + JSON.stringify(res));
        if (res.code == constants.interfaceResult.success) {
          resolve(res.data);
        } else {
          if (getToken() && res.msg !== "") {
            message.error({
              message: "Token expired, login again",
              showClose: true,
              duration: 4000,
              offset: 100,
            });
          }
          reject(res.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// request拦截器
axios.interceptors.request.use(
  (config) => {
    // if (getToken()) {

    // }
    config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token
    //config.headers["Authorization"] = "";
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response 拦截器
axios.interceptors.response.use(
  (response) => {
    // 接收后台参数状态
    if (response.status == 200 && response.data.code == 0) {
      return response.data;
    } else {
      let res = response.data;
      // 这里可以设置后台返回状态码是500或者是其他,然后重定向跳转
      if (res.code == 400 || res.code == 401) {
        removeToken();
        window.globalRouter.push({ path: "/login" });
        message.error({
          message: "Token expired, login again",
          duration: 4000,
          offset: 80,
        });
      } else {
        message.error({
          message: res.msg,
          duration: 4000,
          offset: 80,
        });
      }
      //110698725
      return Promise.reject(
        new Error(res.msg || (res.error && res.error.message) || "System error")
      );
    }
  },
  (error) => {
    if (error.response) {
      // error.response有错误信息,是接口错误,不是取消请求
      // 获取错误码,弹出提示信息,reject()
      let code = error.response.status;
      switch (code) {
        case 400:
        case 401:
          removeToken();
          window.globalRouter.push({ path: "/login" });
          return Promise.reject("Token expired, login again");
        case 403:
          MessageBox.confirm(
            "The login status has expired. You can stay on this page or log in again",
            "System prompt",
            {
              confirmButtonText: "Log in",
              cancelButtonText: "cancel",
              type: "warning",
            }
          ).then(() => {
            removeToken();
            window.globalRouter.push({ path: "/login" });
          });
          return Promise.reject("Token expired, login again");
        case 301:
        case 302:
        case 304:
        case 305:
        case 402:
        case 404:
        case 407:
        case 415:
        case 417:
        case 426:
        case 429:
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
        case 999:
          error.message =
            "System unknown error, please feedback to customer service";
          break;
      }
      message.error({
        message: error.message,
        duration: 4000,
        offset: 80,
      });
      return Promise.reject(error.message);
    } else {
      // 是取消请求
      // 直接reject
      return Promise.reject(error.message);
    }
  }
);
